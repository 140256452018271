import { IconInterface } from "./visibilityIcon";

const PaymentFailureIcon = (props: IconInterface) => {
  return (
    <div className="svg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={props.height || "24px"}
        viewBox="0 0 24 24"
        width={props.width || "24px"}
        fill={props.fill || "#000000"}
      >
        <g>
          <path d="M0,0h24v24H0V0z" fill="none" />
        </g>
        <g>
          <path d="M15.73,3H8.27L3,8.27v7.46L8.27,21h7.46L21,15.73V8.27L15.73,3z M19,14.9L14.9,19H9.1L5,14.9V9.1L9.1,5h5.8L19,9.1V14.9z M14.83,7.76L12,10.59L9.17,7.76L7.76,9.17L10.59,12l-2.83,2.83l1.41,1.41L12,13.41l2.83,2.83l1.41-1.41L13.41,12l2.83-2.83 L14.83,7.76z" />
        </g>
      </svg>
    </div>
  );
};

export default PaymentFailureIcon;
