import { IconInterface } from "../visibilityIcon";

export const HiperIcon = (props: IconInterface) => {
  return (
    <div className="svg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={props.height || "48px"}
        width={props.width || "48px"}
        fill={props.fill || "#000000"}
        version="1.1"
        viewBox="0 0 780 500"
      >
        <path
          className="st0"
          d="M724.3,0H55.7C25.4,0,0.8,24.6,0.8,54.9v390.2c0,30.3,24.6,54.9,54.9,54.9h668.7c30.3,0,54.9-24.6,54.9-54.9
	V54.9C779.2,24.6,754.6,0,724.3,0z"
        />
        <g>
          <polygon
            className="st1"
            points="100,139.9 100,314.9 139.7,314.9 139.7,241.7 204.9,241.7 204.9,314.9 244.4,314.9 244.4,139.9 
		204.9,139.9 204.9,207.2 139.7,207.2 139.7,139.9 100,139.9 	"
          />
          <path
            className="st1"
            d="M586.2,264c0.5-2.9,1.3-8.8,1.3-15.6c0-31.4-15.6-63.4-56.6-63.4c-44.1,0-64.1,35.6-64.1,67.8
		c0,39.7,24.7,64.7,67.8,64.7c17.1,0,33-2.6,46-7.8l-5.2-26.7c-10.6,3.4-21.6,5.2-35.1,5.2c-18.4,0-34.5-7.8-35.8-24.1H586.2
		L586.2,264z M504.1,237c1-10.6,7.8-25.7,24.4-25.7c18.2,0,22.3,16.1,22.3,25.7H504.1L504.1,237z"
          />
          <path
            className="st1"
            d="M600.1,314.9h39.5v-64.4c0-3.1,0.3-6.2,0.8-8.8c2.6-12.2,12.5-20,27-20c4.4,0,7.8,0.5,10.6,1v-37.1
		c-2.9-0.5-4.7-0.5-8.3-0.5c-12.2,0-27.8,7.8-34.3,26.2h-1l-1.3-23.4h-34c0.5,10.9,1,23.1,1,41.8V314.9L600.1,314.9z"
          />
          <path
            className="st2"
            d="M284.5,132.9c11.2,0,20.3,9.1,20.3,20.3c0,11.2-9.1,20.3-20.3,20.3c-11.2,0-20.3-9.1-20.3-20.3
		C264.2,142,273.3,132.9,284.5,132.9L284.5,132.9z"
          />
          <path
            className="st3"
            d="M367.9,285.3h19.8c19.9,0,28.9-12.7,28.9-26c0-13.3-1-43.3-25.5-43.3c-28.2,0-23.7,37.3-23.5,56.8
		C367.5,277,367.8,281.2,367.9,285.3L367.9,285.3z M264.2,187.8h40.6v71.6c0,13.3,7.4,26,23.7,26c0.1-32.2,0-65.4-1.1-97.6h33.9
		c0.7,6.2,1.4,12.4,2,18.6c16-32,66.5-25,83.5,2.1c17.5,27.9,23.5,107.9-59.1,107.9h-19.2c0.2,16.2,0.2,32.5,0.2,48.8h-40.6
		c0-15.6,0.1-32,0.2-48.8c-43.9-0.2-64.1-28-64.1-57V187.8L264.2,187.8z"
          />
        </g>
      </svg>
    </div>
  );
};
