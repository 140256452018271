import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import VisibilityOffIcon from "../icons/visibilityOffIcon";
import VisibilityIcon from "../icons/visibilityIcon";
import { AmexIcon } from "../icons/cardTypes/amex";
import queryString from "query-string";
import creditCardType from "credit-card-type";
import "./payment.scss";
import moment from "moment";
import FormError from "../formError/formError";
import { MasterCardIcon } from "../icons/cardTypes/mastercard";
import { VisaIcon } from "../icons/cardTypes/visa";
import { DiscoverIcon } from "../icons/cardTypes/discover";
import { JcbIcon } from "../icons/cardTypes/jcb";
import { UnionPayIcon } from "../icons/cardTypes/unionpay";
import { MaestroIcon } from "../icons/cardTypes/maestro";
import { MirIcon } from "../icons/cardTypes/mir";
import { DinersClubIcon } from "../icons/cardTypes/dinersClub";
import { HiperIcon } from "../icons/cardTypes/hiper";
import { HipercardIcon } from "../icons/cardTypes/hipercard";
import { EloIcon } from "../icons/cardTypes/elo";
import StoreDetails from "../storeDetails/storeDetails";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../reducers";
import { CardPaymentInterface } from "../../interfaces/cardPaymentInterface";
import { makeStorePaymentAction } from "../../actions/storeActions";
import PaymentStatus from "../paymentStatus/paymentStatus";
import { PaymentLoader } from "../paymentLoader/paymentLoader";
import { LastPaymentInterface } from "../../interfaces/storeInterfaces";
import PreviousPayment from "../previousPayment/previousPayment";
const Payment = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [cardNumber, setCardNumber] = useState<string>("");
  const [cardHolderName, setCardHolderName] = useState<string>("");
  const [cardType, setCardType] = useState<string>("");
  const [orderRef, setOrderRef] = useState<string>("Foodhub Transactions");
  const [cardExpiry, setCardExpiry] = useState<string>("");
  const [cardCvv, setCardCvv] = useState<string>("");
  const [isShowCvv, setIsShowCvv] = useState<boolean>(false);
  const [cardExpiryError, setCardExpiryError] = useState<string>("");
  const [retryCard, setRetryCard] = useState<boolean>(false);

  const [authKey, setAuthKey] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const storeDetails = useSelector((state: AppState) => state.storeDetails);
  const paymentDetails = useSelector((state: AppState) => state.paymentDetails);
  useEffect(() => {
    const { authKey: auth, token: tok } = queryString.parse(location.search);
    if (auth && tok) {
      setAuthKey(auth as string);
      setToken(tok as string);
    }
  }, []);

  useEffect(() => {
    const card = cardNumber.split(" ").join("");
    if (card) {
      if (creditCardType(card).length) {
        setCardType(creditCardType(card)[0].type);
      } else setCardType("unknown");
    } else {
      setCardType("");
    }
  }, [cardNumber]);

  useEffect(() => {
    if (cardExpiry.length > 1) {
      const cardMonth = +cardExpiry.substr(0, 2);
      const cardYear = +cardExpiry.substr(3, 2);
      const currentYear = +moment().format("YY");

      if (cardMonth > 12) {
        setCardExpiryError("Month should be between 01 and 12");
      } else if (cardYear && currentYear && cardYear < currentYear) {
        setCardExpiryError(
          "Year should be equal or greater than the current year"
        );
      } else setCardExpiryError("");
    }

    if (cardExpiry === "") {
      setCardExpiryError("");
    }
  }, [cardExpiry]);

  const cardNumberValidation = (key: string) => {
    const card = cardNumber.split(" ").join("");
    if (/^[0-9]$/.test(key)) {
      if (card.length % 4 === 0 && cardNumber.length) {
        setCardNumber(cardNumber + " " + key);
      } else {
        setCardNumber(cardNumber + key);
      }
    } else if (key === "Backspace" || key === "Delete") {
      const lastCharIndex = cardNumber.length - 1;
      if (cardNumber[lastCharIndex - 1] === " ") {
        setCardNumber(cardNumber.substr(0, lastCharIndex - 1));
      } else {
        setCardNumber(cardNumber.substr(0, lastCharIndex));
      }
    }
  };

  const cardCvvValidation = (key: string) => {
    if (/^[0-9]$/.test(key) && cardCvv.length < 4) {
      setCardCvv(cardCvv + key);
    }
    if (key === "Backspace" || key === "Delete") {
      const lastCharIndex = cardCvv.length - 1;
      setCardCvv(cardCvv.substr(0, lastCharIndex));
    }
  };

  const cardExpiryValidation = (key: string) => {
    const cardDeadline = cardExpiry.split("/").join();
    if (cardDeadline.length < 5) {
      if (/^[0-9]$/.test(key)) {
        if (cardDeadline.length === 2) {
          setCardExpiry(cardExpiry + "/" + key);
        } else {
          setCardExpiry(cardExpiry + key);
        }
      }
    }
    if (key === "Backspace" || key === "Delete") {
      const lastCharIndex = cardExpiry.length - 1;
      if (cardExpiry[lastCharIndex - 1] === "/") {
        setCardExpiry(cardExpiry.substr(0, lastCharIndex - 1));
      } else {
        setCardExpiry(cardExpiry.substr(0, lastCharIndex));
      }
    }
  };

  const resetPaymentForm = () => {
    setCardCvv("");
    setCardExpiry("");
    setCardNumber("");
    setIsShowCvv(false);
    setCardHolderName("");
    setCardExpiryError("");
  };

  const makePayment = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setRetryCard(false);
    if (storeDetails.success) {
      const { billNew, postcode, id } = storeDetails.success.storeDetails;
      const transactionUnique = storeDetails.success.transactionId.toString();

      const payload: CardPaymentInterface = {
        cardNumber: cardNumber.split(" ").join(""),
        amount: +Math.abs(+billNew).toFixed(2),
        cardCVV: cardCvv,
        cardExpiryMonth: cardExpiry.split("/")[0],
        cardExpiryYear: cardExpiry.split("/")[1],
        customerName: cardHolderName,
        customerPostCode: postcode,
        orderRef,
        transactionUnique,
        storeId: id,
      };
      dispatch(makeStorePaymentAction(payload));
    }
  };

  const renderVisibilityIcon = () => {
    if (isShowCvv) {
      return <VisibilityIcon fill={"#7D7D7D"} />;
    }
    return <VisibilityOffIcon fill={"#7D7D7D"} />;
  };

  const renderCardTypeIcon = () => {
    if (cardType.toLowerCase() === "american-express") {
      return <AmexIcon />;
    } else if (cardType.toLowerCase() === "mastercard") {
      return <MasterCardIcon />;
    } else if (cardType.toLowerCase() === "visa") {
      return <VisaIcon />;
    } else if (cardType.toLowerCase() === "discover") {
      return <DiscoverIcon />;
    } else if (cardType.toLowerCase() === "jcb") {
      return <JcbIcon />;
    } else if (cardType.toLowerCase() === "unionpay") {
      return <UnionPayIcon />;
    } else if (cardType.toLowerCase() === "maestro") {
      return <MaestroIcon />;
    } else if (cardType.toLowerCase() === "mir") {
      return <MirIcon />;
    } else if (cardType.toLowerCase() === "diners-club") {
      return <DinersClubIcon />;
    } else if (cardType.toLowerCase() === "hiper") {
      return <HiperIcon />;
    } else if (cardType.toLowerCase() === "hipercard") {
      return <HipercardIcon />;
    } else if (cardType.toLowerCase() === "elo") {
      return <EloIcon />;
    }
  };

  const renderStoreDetails = () => {
    if (authKey && token) {
      return <StoreDetails authKey={authKey} token={token} />;
    }
  };

  const renderPayMessage = () => {
    if (storeDetails.success) {
      const { currency } = storeDetails.success;
      return `Pay  ${currency.sign}${Math.abs(+currency.price).toFixed(2)}`;
    }
    return null;
  };

  const renderPaymentPage = () => {
    if (storeDetails.isLoading) {
      return (
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      );
    } else if (storeDetails.success) {
      return (
        <div className="card-details">
          <form
            className="card-form"
            autoComplete="off"
            onSubmit={(e) => makePayment(e)}
          >
            <div className="card-name">
              <div className="form-field">
                <input
                  name="name"
                  value={cardHolderName}
                  type="text"
                  onChange={(e) => setCardHolderName(e.target.value)}
                  placeholder="Name"
                />
                <label htmlFor="name">Name</label>
              </div>
            </div>

            <div className="card-number card-number-wrapper">
              <div className="form-field">
                <input
                  name="card-number"
                  placeholder="Card Number"
                  value={cardNumber}
                  onKeyDown={(e) => cardNumberValidation(e.key)}
                  inputMode="numeric"
                />
                <label htmlFor="card-number">Card Number</label>
              </div>
              <div className="card-type">{renderCardTypeIcon()}</div>
            </div>

            <div className="card-info-more">
              <div className="form-field">
                <input
                  name="card-expiry"
                  placeholder="MM/YY"
                  value={cardExpiry}
                  onKeyDown={(e) => cardExpiryValidation(e.key)}
                  inputMode="numeric"
                />
                <label htmlFor="card-expiry">Expiry Date</label>
                {cardExpiryError && (
                  <FormError errorMessage={cardExpiryError} />
                )}
              </div>
              <div className="cvv-wrapper">
                <div className="form-field">
                  <input
                    name="card-cvv"
                    placeholder="CVV"
                    value={cardCvv}
                    type={isShowCvv ? "text" : "password"}
                    inputMode="numeric"
                    onKeyDown={(e) => cardCvvValidation(e.key)}
                  />
                  <label htmlFor="card-cvv">CVV</label>
                </div>
                <div
                  className="form-field pointer"
                  onClick={() => setIsShowCvv(!isShowCvv)}
                >
                  {renderVisibilityIcon()}
                </div>
              </div>
            </div>

            <div className="card-name">
              <div className="form-field">
                <input
                  name="order-ref"
                  value={orderRef}
                  type="text"
                  onChange={(e) => setOrderRef(e.target.value)}
                  placeholder="Reference"
                />
                <label htmlFor="order-ref">Reference</label>
              </div>
            </div>

            <div className="btn-group">
              <div className="form-field">
                <button
                  type="button"
                  onClick={resetPaymentForm}
                  className="btn-reset"
                >
                  Reset
                </button>
                <button type="submit" className="btn-pay">
                  {renderPayMessage()}
                </button>
              </div>
            </div>
          </form>
        </div>
      );
    }
  };

  const renderCardDetails = () => {
    const {
      error: paymentError,
      success: paymentSuccess,
      isLoading: paymentLoading,
    } = paymentDetails;

    if (retryCard) {
      return renderPaymentPage();
    }
    if (paymentLoading) {
      return (
        <div className="payment-loader">
          <PaymentLoader />
        </div>
      );
    } else if (paymentSuccess) {
      const { paymentStatus, transactionLog } = paymentSuccess;

      if (paymentStatus) {
        return (
          <PreviousPayment
            paymentLog={transactionLog}
            isPaymentSuccessful={true}
            paymentClass={"payment-success"}
          />
        );
      }
      return (
        <>
          <PreviousPayment
            paymentLog={transactionLog}
            isPaymentSuccessful={false}
            paymentClass={"payment-failure"}
          />
          {paymentSuccess.retryPayment ? (
            <button className="btn-retry" onClick={() => setRetryCard(true)}>
              Retry
            </button>
          ) : (
            ""
          )}
        </>
      );
    } else if (paymentError) {
      return (
        <>
          <PreviousPayment
            paymentLog={{}}
            isPaymentSuccessful={false}
            paymentClass={"payment-failure"}
          />
          <button className="btn-retry" onClick={() => setRetryCard(true)}>
            Retry
          </button>
        </>
      );
    }

    if (storeDetails.success) {
      if (retryCard) {
        return renderPaymentPage();
      }

      const {
        isPaymentSuccessful,
        lastTransactionDetails,
        storeDetails: storeInfo,
        retryPayment,
      } = storeDetails.success;

      const paidBill = +Math.abs(+storeInfo.billNew).toFixed(2);

      if (isPaymentSuccessful) {
        if (Object.keys(lastTransactionDetails).length) {
          return (
            <PreviousPayment
              paymentLog={lastTransactionDetails as LastPaymentInterface}
              isPaymentSuccessful={true}
              paymentClass={"payment-success"}
            />
          );
        }
      } else {
        if (Object.keys(lastTransactionDetails).length) {
          return (
            <>
              <PreviousPayment
                paymentLog={lastTransactionDetails as LastPaymentInterface}
                isPaymentSuccessful={false}
                paymentClass={"payment-failure"}
              />
              {retryPayment ? (
                <button
                  className="btn-retry"
                  onClick={() => setRetryCard(true)}
                >
                  Retry
                </button>
              ) : (
                ""
              )}
            </>
          );
        }
      }
    }

    return renderPaymentPage();
  };

  return (
    <>
    <div className="Payment">
      <div className="payment-details">{renderStoreDetails()}</div>
      <div className="payment-card">{renderCardDetails()}</div>
      
    </div>
    <div className="logo row m-0 footer-logo">
    <a href={"https://foodhub.co.uk/"} target="_blank">
                  <img
                    className="fh-logo img-fluid d-block mx-auto"
                    src={ "https://public.touch2success.com/static/core/img/foodhub_powered_by_logo.svg"}
                    alt={"FOODHUB"}
                    title={"FOODHUB"}
                    height="100%"
                    width="100%"
                   />
                </a>
    </div>
    </>
  );
};

export default Payment;
