import { StoreActionTypes, StoreActions } from "../../actionTypes/storeActions";
import { StoreDetailsResponseInterface } from "../../interfaces/storeInterfaces";

interface InitialStateInterface {
  isLoading: boolean;
  success: StoreDetailsResponseInterface | null;
  error: string | null;
}

const initialState: InitialStateInterface = {
  isLoading: true,
  success: null,
  error: null,
};

export const getStoreDetailsReducer = (
  state: InitialStateInterface = initialState,
  action: StoreActions
) => {
  switch (action.type) {
    case StoreActionTypes.GET_STORE_DETAILS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
