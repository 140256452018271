import * as _ from "lodash";
import config from "../config/api";
/**
 *  @param {} data
 * @returns
 */
const encodeBase64 = (data: string) => {
  return window.btoa(data);
};

/**
 *
  @param {} data
 * @returns
 */
export const encrypt = (data: string) => {
  const ret = `${config.CARD_SECRET_KEY}|${data}|${config.CARD_SECRET_KEY}`;
  let charCodeArr: any = [];

  // ascii conbversion
  for (let i = 0; i < ret.length; i++) {
    let code = ret.charCodeAt(i).toString(24);
    charCodeArr.push(code);
  }

  charCodeArr = _.chain(charCodeArr).join(":").value();

  return encodeBase64(charCodeArr);
};
