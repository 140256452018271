import axios from "axios";
import { Dispatch } from "redux";
import { StoreActionTypes, StoreActions } from "../actionTypes/storeActions";
import { StoreDetailsProps } from "../components/storeDetails/storeDetails";
import { CardPaymentInterface } from "../interfaces/cardPaymentInterface";
import API_URL from "../config/api";
import { encrypt } from "../lib/crypto.lib";
export const getStoreDetails =
  (params: StoreDetailsProps) => async (dispatch: Dispatch<StoreActions>) => {
    dispatch({
      type: StoreActionTypes.GET_STORE_DETAILS,
      payload: {
        isLoading: true,
        success: null,
        error: null,
      },
    });

    try {
      const storeDetails = await axios({
        method: "GET",
        params,
        url: API_URL.STORE_API,
      });

      return dispatch({
        type: StoreActionTypes.GET_STORE_DETAILS,
        payload: {
          isLoading: false,
          success: storeDetails.data,
          error: null,
        },
      });
    } catch (error) {
      return dispatch({
        type: StoreActionTypes.GET_STORE_DETAILS,
        payload: {
          isLoading: false,
          success: null,
          error: (error as Error).message,
        },
      });
    }
  };

export const makeStorePaymentAction =
  (params: CardPaymentInterface) =>
  async (dispatch: Dispatch<StoreActions>) => {
    dispatch({
      type: StoreActionTypes.MAKE_PAYMENT,
      payload: {
        isLoading: true,
        success: null,
        error: null,
      },
    });

    try {
      params.cardCVV = encrypt(params.cardCVV);
      params.cardNumber = encrypt(params.cardNumber);
      const paymentDetails = await axios({
        method: "POST",
        url: API_URL.API_BILLING,
        data: params,
      });

      return dispatch({
        type: StoreActionTypes.MAKE_PAYMENT,
        payload: {
          isLoading: false,
          success: paymentDetails.data,
          error: null,
        },
      });
    } catch (error) {
      return dispatch({
        type: StoreActionTypes.MAKE_PAYMENT,
        payload: {
          isLoading: false,
          success: null,
          error: (error as Error).message,
        },
      });
    }
  };
