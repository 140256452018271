import "./formError.scss";
interface FormErrorProps {
  errorMessage: string;
}

const FormError = ({ errorMessage }: FormErrorProps) => {
  return (
    <div className="error-msg">
      <span className="arrow-up"></span>
      {errorMessage}
    </div>
  );
};

export default FormError;
