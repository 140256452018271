import { combineReducers } from "redux";
import { getStoreDetailsReducer } from "./storeReducers/getStoreDetailsReducer";
import { makeStorePaymentReducer } from "./storeReducers/makeStorePaymentReducer";

const reducer = combineReducers({
  storeDetails: getStoreDetailsReducer,
  paymentDetails: makeStorePaymentReducer,
});

export default reducer;
export type AppState = ReturnType<typeof reducer>;
