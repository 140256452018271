import { StoreActionTypes, StoreActions } from "../../actionTypes/storeActions";
import { StorePaymentResponseInterface } from "../../interfaces/storeInterfaces";

interface InitialStateInterface {
  isLoading: boolean;
  success: StorePaymentResponseInterface | null;
  error: string | null;
}

const initialState: InitialStateInterface = {
  isLoading: false,
  success: null,
  error: null,
};

export const makeStorePaymentReducer = (
  state: InitialStateInterface = initialState,
  action: StoreActions
) => {
  switch (action.type) {
    case StoreActionTypes.MAKE_PAYMENT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
