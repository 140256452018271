import Payment from "./components/payment/payment";
import { BrowserRouter, Switch, Route, useHistory } from "react-router-dom";
import "./App.css";
import NotFound from "./components/notFound/notFound";

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/payments" exact component={Payment} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};
export default App;
