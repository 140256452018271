import moment from "moment";
import { LastPaymentInterface } from "../../interfaces/storeInterfaces";
import "./previousPayment.scss";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../reducers";

interface PreviousPaymentProps {
  paymentLog: LastPaymentInterface | {};
  paymentClass: string;
  isPaymentSuccessful: boolean;
}

const PreviousPayment = ({
  paymentLog,
  isPaymentSuccessful,
  paymentClass,
}: PreviousPaymentProps) => {
  const storeDetails = useSelector((state: AppState) => state.storeDetails);

  if (storeDetails.success) {
    let { currency } = storeDetails.success;

    const renderMessage = () => {
      if (Object.keys(paymentLog).length) {
        let {
          id,
          createdAt,
          topupAmount,
          cardIssuer,
          responseMessage,
          last4Digits,
        } = paymentLog as LastPaymentInterface;

        const timeRemaining = 15 - moment().diff(moment(createdAt), "minutes");
        if (isPaymentSuccessful) {
          return (
            <div className="payment-header">
              Your last payment was successful.
              <div className="payment-content">
                <div className="payment-content-header">
                  <ul>
                    <li>Payment Initiated Time:</li>
                    <li>Transaction Number:</li>
                    <li>Card Issuer:</li>
                    <li>Payment Amount:</li>
                    <li>Card Ending With:</li>
                  </ul>
                </div>
                <div className="payment-content-value">
                  <ul>
                    <li>{moment(createdAt).format("DD MMM, YYYY - h:mm A")}</li>
                    <li>#{id}</li>
                    <li>{cardIssuer}</li>
                    <li>
                      {currency.sign}
                      {currency.price}
                    </li>
                    <li>{last4Digits}</li>
                  </ul>
                </div>
              </div>
              <div className="disclaimer">
                NOTE: your payment will take upto 20 minutes to reflect in our
                server.
              </div>
            </div>
          );
        } else {
          return (
            <div className="payment-header">
              Your last payment was unsuccessful.
              <div className="payment-content">
                <div className="payment-content-header">
                  <ul>
                    <li>Payment Initiated Time:</li>
                    <li>Transaction Number:</li>
                    <li>Card Issuer</li>
                    <li>Payment Amount:</li>
                    <li>Card Ending With</li>
                    <li>Error Message</li>
                  </ul>
                </div>
                <div className="payment-content-value">
                  <ul>
                    <li>{moment(createdAt).format("DD MMM, YYYY - h:mm A")}</li>
                    <li>#{id}</li>
                    <li>{cardIssuer || "INVALID"}</li>
                    <li>
                      {currency.sign}
                      {currency.price}
                    </li>
                    <li>{last4Digits || "INVALID"}</li>
                    <li>{responseMessage || ""}</li>
                  </ul>
                </div>
              </div>
            </div>
          );
        }
      } else {
        return (
          <div className="payment-header">
            Your last payment was unsuccessful.
            <div className="payment-content">
              <div className="error">
                An Error occured while making the payment
              </div>
            </div>
          </div>
        );
      }
    };
    return (
      <div className="PreviousPayment">
        <div className={`${paymentClass} payment-details`}>
          <div className="message">{renderMessage()}</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="PreviousPayment">
        <div className={`${paymentClass} payment-details`}></div>
      </div>
    );
  }
};

export default PreviousPayment;
