import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStoreDetails } from "../../actions/storeActions";
import { StoreDetailsResponseInterface } from "../../interfaces/storeInterfaces";
import { AppState } from "../../reducers";
import PaymentFailureIcon from "../icons/paymentFailureIcon";
import PaymentSuccessIcon from "../icons/paymentSuccessIcon";
import "./storeDetails.scss";
export interface StoreDetailsProps {
  authKey: string;
  token: string;
}

const StoreDetails = (props: StoreDetailsProps) => {
  const dispatch = useDispatch();
  const storeDetails = useSelector((state: AppState) => state.storeDetails);
  const paymentDetails = useSelector((state: AppState) => state.paymentDetails);
  useEffect(() => {
    dispatch(getStoreDetails(props));
  }, []);

  const renderStoreData = () => {
    if (storeDetails.success) {
      const {
        billNew,
        city,
        postcode,
        street,
        town,
        websiteLogoUrl,
        name,
        id: storeId,
      } = storeDetails.success.storeDetails;

      const renderPaymentStatusIcon = () => {
        const { success, error } = paymentDetails;
        const { isPaymentSuccessful } =
          storeDetails.success as StoreDetailsResponseInterface;

        if (success || isPaymentSuccessful) {
          if (success?.paymentStatus || isPaymentSuccessful) {
            return <PaymentSuccessIcon fill="#5bae75" />;
          }
          return (
            <PaymentFailureIcon fill="#ea332f" height={"32px"} width={"32px"} />
          );
        } else if (error || isPaymentSuccessful === false) {
          return (
            <PaymentFailureIcon fill="#ea332f" height={"32px"} width={"32px"} />
          );
        }
        return null;
      };

      const { niceName: formattedCountryName } =
        storeDetails.success.countryDetails;
      const { currency } = storeDetails.success;
      return (
        <div className="store-details-wrapper">
          <div className="store-logo">
            <img src={websiteLogoUrl as string} alt="Store Logo" />
          </div>
          <div className="store-name">{name}</div>
          <div className="address">
            <span className="street">{street}, </span>
            <span className="town">{town} </span>
            <br />
            <span className="city">{city}, </span>
            <span className="country">{formattedCountryName}</span>
            <br />
            <span className="postcode">{postcode}</span>
          </div>
          <div className="bill">
            <span>Total Bill: </span>
            <span className="bill-amount">
              {currency.sign}
              {Math.abs(+currency.price).toFixed(2)}
            </span>
            {renderPaymentStatusIcon()}
          </div>
        </div>
      );
    }
    return null;
  };

  return <div className="StoreDetails">{renderStoreData()}</div>;
};

export default StoreDetails;
