import {
  StoreDetailsResponseInterface,
  StorePaymentResponseInterface,
} from "../interfaces/storeInterfaces";

export enum StoreActionTypes {
  GET_STORE_DETAILS = "GET_STORE_DETAILS",
  MAKE_PAYMENT = "MAKE_PAYMENT",
}

export interface GetStorePayloadInterface {
  isLoading: boolean;
  success: StoreDetailsResponseInterface | null;
  error: string | null;
}

export interface GetStorePaymentInterface {
  isLoading: boolean;
  success: StorePaymentResponseInterface | null;
  error: string | null;
}

export interface GetStoreActionInterface {
  type: typeof StoreActionTypes.GET_STORE_DETAILS;
  payload: GetStorePayloadInterface;
}

export interface MakeStorePaymentActionInterface {
  type: typeof StoreActionTypes.MAKE_PAYMENT;
  payload: GetStorePaymentInterface;
}

export type StoreActions =
  | GetStoreActionInterface
  | MakeStorePaymentActionInterface;
